<template>
    <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item label="日期">
                <el-date-picker v-model="dataForm.timeArr" type="daterange" value-format="yyyy-MM-dd" range-separator="——"
                    start-placeholder="开始日期" end-placeholder="结束日期" align="right" :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="语言">
                <el-select v-model="dataForm.langs" clearable placeholder="语言">
                    <el-option v-for="item in langOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getDataList()" type="primary" size="small">查询</el-button>
                <el-button @click="exportList()" type="primary" size="small">导出</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
            style="width: 100%;">
            <el-table-column type="index" header-align="center" align="center" width="60" label="序号">
            </el-table-column>
            <el-table-column prop="day" header-align="center" align="center" width="150" label="日期">
            </el-table-column>
            <el-table-column prop="lang" header-align="center" align="center" label="语言">
            </el-table-column>
            <el-table-column prop="totalToday" header-align="center" align="center" label="当天客诉总量">
            </el-table-column>
            <el-table-column prop="finishToday" header-align="center" align="center" label="当日处理总量">
            </el-table-column>
            <el-table-column prop="unFinishToday" header-align="center" align="center" label="当日未处理量">
            </el-table-column>
            <el-table-column prop="unFinishUntilToday" header-align="center" align="center" label="截止当日未处理量">
            </el-table-column>
        </el-table>
        <!-- <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination> -->
    </div>
</template>
<script>
import { langOpt, pickerOptions } from "@/views/common/commonOpt";

export default {
    data() {
        return {
            dataForm: {
                timeArr: [],
                langs: 'all'
            },
            langOptions: langOpt,
            dataList: [],
            // pageIndex: 1,
            // pageSize: 10,
            // totalPage: 0,
            dataListLoading: false,
            pickerOptions: pickerOptions,
        }
    },
    mounted() {

    },
    methods: {
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true
            let startDay = (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null
            let endDay = (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null
            this.$http({
                url: this.$http.adornUrl(`/cs/table/list?startDay=${startDay}&endDay=${endDay}&lang=${this.dataForm.langs}`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.result
                } else {
                    this.dataList = []
                    this.$message.error(data.msg)
                }
                this.dataListLoading = false
            })
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 导出
        exportList() {
            let startDay = (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null
            let endDay = (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null
            // window.open(
            //     this.$http.adornUrl('/cs/table/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
            //     "_blank"
            // );
            window.location.href = this.$http.adornUrl('/cs/table/export') + `?token=${this.$cookie.get('token')}&startDay=${startDay}&endDay=${endDay}&lang=${this.dataForm.langs}`
        }
    },
}
</script>
<style scoped></style>
